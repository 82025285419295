const forEach = require('lodash.foreach');
const isString = require('lodash.isstring');

const smallBreakpoint = 320;
const mediumBreakpoint = 480;
const largeBreakpoint = 768;
const desktopBreakpoint = 1025;
const maxBreakpoint = 1280;
const mobileMenuBreakpoint = desktopBreakpoint;

const util = {
    /**
     * @desc Media breakpoints that are used throughout the Javascript
     */
    breakpoints: {
        xs: smallBreakpoint,
        sm: mediumBreakpoint,
        md: largeBreakpoint,
        lg: desktopBreakpoint,
        xl: maxBreakpoint,
        'mobile-menu': mobileMenuBreakpoint,
    },

    /**
     * @function
     * @description Returns either an object with all of the available breakpoints or a specific viewport based on the given size
     * @param {string} size The viewport to return
     * @param {string} breakpoints A custom breakpoints object
     */
    getViewports(size, breakpoints) {
        const bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;

        if (typeof size !== 'undefined') {
            if (bps[size]) {
                return bps[size];
            }
            window.console.error('Unexpected viewport size given in util.getViewports');
            throw new Error('Unexpected viewport size given in util.getViewports');
        } else {
            return breakpoints;
        }
    },

    /**
     * @function
     * @description Returns the current viewport name (ex: 'medium') or 'max' if the current window is larger than any defined viewport width
     */
    getCurrentViewport() {
        const w = window.innerWidth;
        const viewports = util.getViewports();
        let viewportName = 'max';
        // traverse the object from small up to desktop, and return the first match
        forEach(viewports, (value, name) => {
            if (w <= value) {
                viewportName = name;
                return false;
            }
            return true;
        });
        return viewportName;
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL(url, name, value) {
        // quit if the param already exists
        if (url.indexOf(`${name}=`) !== -1) {
            return url;
        }
        const separator = url.indexOf('?') !== -1 ? '&' : '?';
        return `${url + separator + name}=${encodeURIComponent(value)}`;
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL(url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(`${name}=`) === -1) {
            return url;
        }
        const [domain, query] = url.split('?');
        const newParams = [];
        let paramUrl = query;
        let hash = null;

        // if there is a hash at the end, store the hash
        if (query.indexOf('#') > -1) {
            [paramUrl, hash] = query.split('#');
        }
        const params = paramUrl.split('&');
        for (let i = 0; i < params.length; i += 1) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return `${domain}?${newParams.join('&')}${hash ? `#${hash}` : ''}`;
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl(url, params) {
        let newUrl = url;
        forEach(params, (value, name) => {
            newUrl = this.appendParamToURL(newUrl, name, value);
        });
        return newUrl;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     * */
    getQueryString(url) {
        let qs;
        if (!isString(url)) { return null; }
        const a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport(el, offsetToTop) {
        const width = el.offsetWidth;
        const height = el.offsetHeight;
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let currentElement = el;

        while (currentElement.offsetParent) {
            currentElement = currentElement.offsetParent;
            top += currentElement.offsetTop;
            left += currentElement.offsetLeft;
        }

        if (typeof (offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight)
                && left < (window.pageXOffset + window.innerWidth)
                && (top + height) > window.pageYOffset
                && (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight)
                && left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth)
                && (top + height) > window.document.documentElement.scrollTop
                && (left + width) > window.document.documentElement.scrollLeft
            );
        }
        return false;
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl(path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl(url) {
        return url.indexOf('http') !== 0 && url.charAt(0) !== '/' ? `/${url}` : url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss(urls) {
        const len = urls.length;
        for (let i = 0; i < len; i += 1) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile(url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet',
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss() {
        const len = this.loadedCssFiles.length;
        for (let i = 0; i < len; i += 1) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Loads js dynamically from given urls
     * @param {Array} urls Array of urls from which js will be dynamically loaded.
     */
    loadDynamicJs(urls) {
        const len = urls.length;
        for (let i = 0; i < len; i += 1) {
            this.loadedJsFiles.push(this.loadJsFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads js file dynamically from given url
     * @param {String} url The url from which js file will be dynamically loaded.
     */
    loadJsFile(url) {
        return $('<script/>').appendTo($('body')).attr({
            type: 'text/javascript',
        }).attr('src', url);
    },
    // array to keep track of the dynamically loaded JS files
    loadedJsFiles: [],

    /**
     * @function
     * @description Removes all js files which were dynamically loaded
     */
    clearDynamicJs() {
        const len = this.loadedJsFiles.length;
        for (let i = 0; i < len; i += 1) {
            $(this.loadedJsFiles[i]).remove();
        }
        this.loadedJsFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams(qs) {
        if (!qs || qs.length === 0) { return {}; }
        const url = qs.toString();
        const params = {};

        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        url.replace(
            /([^?=&]+)(=([^&]*))?/g,
            ($0, $1, $2, $3) => {
                params[$1] = decodeURIComponent($3);
            },
        );
        return params;
    },

    fillAddressFields(address, $form) {
        forEach(address, (value, field) => {
            if (field !== 'ID' && field !== 'UUID' && field !== 'key') {
                // if the key in address object ends with 'Code', remove that suffix
                // keys that ends with 'Code' are postalCode, stateCode and countryCode
                $form.find(`[name$="${field.replace('Code', '')}"]`).val(address[field]);
                // update the state fields
                if (field === 'countryCode') {
                    $form.find('[name$="country"]').trigger('change');
                    // retrigger state selection after country has changed
                    // this results in duplication of the state code, but is a necessary evil
                    // for now because sometimes countryCode comes after stateCode
                    $form.find('[name$="state"]').val(address.stateCode);
                }
            }
        });
    },

    fillAddressDisplay(address, $container) {
        forEach(address, (value, field) => {
            if (field !== 'ID' && field !== 'UUID' && field !== 'key') {
                $container.find(`.${field}`).text(address[field]);
            }
        });
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters() {
        $('form').find('textarea[data-character-limit], input[data-character-limit]').each((index, element) => {
            const characterLimit = $(element).data('character-limit');
            const charCountHtml = String.format(
                Resources.CHAR_LIMIT_MSG,
                `<span class="char-remain-count">${characterLimit}</span>`,
            );
            let charCountContainer = $(element).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(element));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(element).change();
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation(container, message) {
        $(container).on('click', '.delete', () => window.confirm(message));
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser(xLocation, callback) {
        $('html, body').animate({scrollTop: xLocation}, 500, callback);
    },

    /**
     * @function
     * @desc Determines if the device that is being used is mobile
     * @returns {Boolean}
     */
    isMobile() {
        const mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        let idx = 0;
        let isMobile = false;
        const userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx += 1;
        }
        return isMobile;
    },

    /**
     * Executes a callback function when the user has stopped resizing the screen.
     *
     * @param   {function}  callback
     * @var     obj         timeout
     *
     * @return  {function}
     */
    smartResize(callback) {
        let timeout;

        $(window).on('resize', () => {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 100);
        }).resize();

        return callback;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints (optional)
     */
    mediaBreakpointUp(size, breakpoints) {
        const breakpoint = this.getViewports(size, breakpoints);
        const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);
        return mediaQuery.matches;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointDown(size, breakpoints) {
        const bps = typeof breakpoints !== 'undefined' ? breakpoints : this.breakpoints;
        const nextSize = this.getNextObjectKey(bps, size);

        if (typeof nextSize === 'string') {
            const breakpoint = this.getViewports(nextSize, breakpoints) - 1;
            const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);
            return mediaQuery.matches;
        }
        return true;
    },

    /**
     * @function
     * @desc Generates a min-width and max-width matchMedia media queries based on the given params
     * @param {string} sizeMin - Min breakpoint to use for the media query
     * @param {string} sizeMax - Max breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointBetween(sizeMin, sizeMax, breakpoints) {
        const min = this.mediaBreakpointUp(sizeMin, breakpoints);
        const max = this.mediaBreakpointDown(sizeMax, breakpoints);

        return min && max;
    },

    /**
     * @function
     * @desc Generates a min-width and max-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {object} breakpoints - Override of the util breakpoints object (optional)
     */
    mediaBreakpointOnly(size, breakpoints) {
        return this.mediaBreakpointBetween(size, size, breakpoints);
    },

    /**
     * @function
     * @desc Retrieves the next key in the object or null if it doesn't exist
     * @returns {string}|{null}
     */
    getNextObjectKey(obj, key) {
        const keys = Object.keys(obj);
        const nextIndex = keys.indexOf(key) + 1;

        if (keys.length > nextIndex) {
            return keys[nextIndex];
        }
        return null;
    },

    /**
     * @function
     * @desc Retrieves the util breakpoints object
     * @returns {object}
     */
    getBreakpoints() {
        return this.breakpoints;
    },

    /**
     * @function
     * @description Generate svg icon
     * @param icon  {String} svg icon name
     * @returns {String} SVG tag
     */
    svg(icon, extraClasses) {
        const classes = extraClasses || '';
        return `<svg class="icon ${icon} ${classes} svg-${icon}-dims"><use xlink:href="#${icon}"/></svg>`;
    },

    backToTop() {
        const bttScrollTriggerDesktop = window.SitePreferences.BTT_DESKTOP;
        const bttScrollTriggerMobile = window.SitePreferences.BTT_MOBILE;
        const scrollTop = $(window).scrollTop();
        const desktopQuery = matchMedia('(min-width: 768px)');
        let bttScrollTrigger;

        if (desktopQuery.matches) {
            bttScrollTrigger = bttScrollTriggerDesktop;
        } else {
            bttScrollTrigger = bttScrollTriggerMobile;
        }

        if (scrollTop > bttScrollTrigger) {
            $('.back-to-top').addClass('show');
        } else {
            $('.back-to-top').removeClass('show');
        }
    },

    /**
     * @function
     * @description Sets checkbox values and aria attributes
     * @param {String} input
     */
    setCheckboxValues(input) {
        const $hiddenInput = $(input).parents('.checkbox').find('.input-checkbox');
        if ($hiddenInput.is(':checked')) {
            $hiddenInput.val(false).attr('aria-checked', 'false');
        } else {
            $hiddenInput.val(true).attr('aria-checked', 'true');
        }
    },

    /**
     * @function
     * @description Set's a mutation observer on the einstein product carousel to know when it can init
     * @param {String} carouselContainerSelector
     * @param {String} tileContainerSelector
     * @param {Number} specificSlotSelector
     * @param {Number} numberOfSlides
     * @example util.initDynamicCarousel('Dom-Selector', 'Dom-Selector', jQeury Object Index, Number of slides);
     * */
    initDynamicCarousel(carouselContainerSelector, tileContainerSelector, specificSlotSelector, numberOfSlides) {
        // Pass in param that defines which slot on the page we trying to init if there is only one on the page pass in 0
        const specificSlot = specificSlotSelector || 0;
        // Grab the defined tile recommendation tile container
        const carouselContainer = $(carouselContainerSelector).get(specificSlot);
        // If we don't get a result for any reason return us out
        if (!carouselContainer) {
            return;
        }
        // Mutation observer config
        const carouselObserverConfig = {childList: true, subtree: true};
        // Define mutation observer action too take
        const dynamicCarouselObserver = new MutationObserver(() => {
            try {
                const $tileContainer = $(carouselContainer).find(tileContainerSelector);
                $tileContainer.not('.slick-initialized').slick({
                    speed: SitePreferences.SLIDE_SHOW_SPEED,
                    dots: false,
                    slide: '.grid-tile',
                    arrows: true,
                    slidesToShow: numberOfSlides || 4,
                    slidesToScroll: 1,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: util.getViewports('lg'),
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: util.getViewports('md'),
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ],
                });
            // This try catch will catch errors with slick as well as if there are not enough slides to fully init the carousel
            } catch (e) {
                console.log(e);
            }
        });
        // Start observing container
        dynamicCarouselObserver.observe(carouselContainer, carouselObserverConfig);
    },
};

module.exports = util;
